import * as React from 'react'
import { Link } from 'gatsby'
let Scroll = require('react-scroll');
import { FiChevronDown } from "react-icons/fi";
let scroller = Scroll.scroller;
var Element = Scroll.Element;
import Ripples from 'react-ripples'


import {SlideDown} from 'react-slidedown'

const Arrow = styled(FiChevronDown)`
font-size:2rem;
margin-bottom:3px;
cursor:pointer;
transition:all .7s ease;
color:var(--textNormal);

`
const Header = styled.h2`
margin-right:10px;
margin-top:0px;
margin-bottom:0px;
cursor:pointer;
`

const StyledSlideDown = styled(SlideDown)`

//padding-left:50px;

@media screen and (max-width:1200px){
padding-left:0px  ;

}
`
const P = styled.p`
font-weight: normal;
font-size: 1.1em;
letter-spacing: 0.1em;
color: #333;
line-height: 1.5em;`


const Flex = styled.div`
width:100%;
display:flex;
justify-content:space-between;


 @media screen and (max-width: 870px) {
 display:block;
 }
`

const B = styled.b`
font-family:${fonts.chapaProBold};
margin-left:7px;
`

const Img = styled.img`

height:805.98px;
margin-left:24px;


 @media screen and (max-width: 870px) {
 margin-top:6em;
width:100%;
height:auto;
margin-left:0px;
 }
`

const Lbl = styled(Ripples)`
display:flex;
flex-direction:row;
align-items:center;
height:50px;
cursor:pointer;
padding-left:4px;
padding-right:4px;

`

const Desk = styled.div`
  @media screen and (max-width: 870px) {
display:none; }
`

const Div=styled.div`
  @media screen and (max-width: 870px) {
text-align:center; }
`
const img = require('../../assets/images/slider/v7_07.jpg')

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import styled from "@emotion/styled";
import OfferForm from "../components/OfferForm";
import {fonts} from "../styles/variables";


class Buchung extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      sOfficeClosed:false,
      sOfficeDetailsClosed:true,
      mOfficeClosed:false,
      mOfficeDetailsClosed:true
    }
    this.toggleSOffice=this.toggleSOffice.bind(this)
    this.toggleMOffice=this.toggleMOffice.bind(this)
    this.toggleSOfficeDetails=this.toggleSOfficeDetails.bind(this)
    this.toggleMOfficeDetails=this.toggleMOfficeDetails.bind(this)

  }
  componentDidMount() {
    scroller.scrollTo('header', {
      duration: 1500,
      smooth: true,
    })
  }


  toggleSOffice(){
    this.setState({
      sOfficeClosed:!this.state.sOfficeClosed

    })


  }
  toggleSOfficeDetails(){
    this.setState({
      sOfficeDetailsClosed:!this.state.sOfficeDetailsClosed

    })

  }
  toggleMOffice(){
    this.setState({
      mOfficeClosed:!this.state.mOfficeClosed

    })

  }
  toggleMOfficeDetails(){
    this.setState({
      mOfficeDetailsClosed:!this.state.mOfficeDetailsClosed

    })


  }

  render() {
    return (
  <IndexLayout>
    <Page>
      <Container>
        <Element id={'header'}/>
        <Desk>
        <br/>
        </Desk>
        <Div>
        <h1>Einfach schöner arbeiten</h1>
        </Div>


          <br/>
        <br/>
        <P>Es sind die feinen Unterschiede, die unseren Gästen das Arbeiten noch angenehmer gestalten – unsere stilvolle und harmonische Einrichtung, unsere hochwertige Ausstattung und ein somit hoher Wohlfühlfaktor. Jedes Private Office ist handverlesen,
          jedes ist etwas Besonderes und in jedem werden Sie sich ganz sicher wie zu Hause fühlen.</P>
<br/>
<P>Hinweis: Die Private Offices sind nicht rollstuhlgerecht, da sie sich im Hochparterre befinden.</P>
        <br/>
        <h2>Neue Bürolösungen entdecken</h2>
        <br/>
        <P>Durch das Kombinieren alter und neuer Designelemente haben wir unverwechselbare Arbeitsbereiche geschaffen,
          in denen sich das Arbeiten angenehm und hocheffizient gestalten lässt.
          Moderne Räume in einer ruhigen Arbeitsumgebung machen unsere Private Offices im Erdgeschoss unseres renovierten Altbaus einzigartig.
          Eine dezente, stilvolle Einrichtung und ein schönes Ambiente sorgen für eine Wohlfühlatmosphäre in den unterschiedlichen Räumlichkeiten.
          Jedes Private Office ist hochwertig eingerichtet und für eine Woche, einen Monat oder länger buchbar. Ob Coworking im Private Office oder in unserem Konferenzraum:
          Bei Vilhelm7 Coworking finden wir genau die Lösung, die auf Ihre Bedürfnisse ausgerichtet ist. </P>
        <br/>

<h2>Unsere Office Pakete:</h2>
        <br/>
        <Lbl onClick={this.toggleSOffice}>
        <Header>PRIVATE OFFICE <B> S</B></Header>
          <Arrow style={{transform: this.state.sOfficeClosed ? 'rotate(0deg)': 'rotate(180deg)'}}/>
        </Lbl>
        <br/>
        <br/>
        <StyledSlideDown closed={this.state.sOfficeClosed} className={'private-office-s'}>
        <P>Authentischer Berliner Altbau-Charme gepaart mit modernstem Design. Spannendste Kombination aus Farben und Materialien.
          Gemütliches Raumwunder mit stylishem Bad.
          Perfekte Größe für 1-4 Personen, mit allen Annehmlichkeiten.</P>
        <br/>
        <P><b>Klein, aber fein – ca. 17 m² für 1-4 Personen</b></P>
        <br/>

        <P>2500,- pro Monat | 650,- pro Woche</P>

        <br/>
          <Lbl onClick={this.toggleSOfficeDetails}>
            <Header>Details</Header>

            <Arrow style={{transform: this.state.sOfficeDetailsClosed ? 'rotate(0deg)': 'rotate(180deg)'}}/>
          </Lbl>
          <br/>
          <br/>
          <StyledSlideDown closed={this.state.sOfficeDetailsClosed} className={'private-office-s-details'}>

          <ul>
          <li>4 Arbeitsplätze möglich</li>
          <li>frisches Obst</li>
          <li>Separater Eingang</li>
          <li>Desinfektionsservice</li>
          <li>Loungebereich</li>
          <li>WLAN</li>
          <li>Große Fenster</li>
          <li>Duschbad mit eigenem WC</li>
          <li>Handtücher / Badetücher</li>
          <li>Kaffee & Tee Bar</li>
        </ul>

        <P>Optional zubuchbare Services:</P>
        <ul>
          <li>Konferenzraum  (25 €/h)</li>
          <li>Minibar mit frischen Getränken (2,00 - 2,80 €)</li>
          <li>Frühstücksservice (15 €)</li>
          <li>Fahrradvermietung (12 €/Tag)</li>
        </ul>
          </StyledSlideDown>
        </StyledSlideDown>
        <br/>
        <br/>
        <Lbl onClick={this.toggleMOffice}>
          <Header>PRIVATE OFFICE <B> M</B></Header>

          <Arrow style={{transform: this.state.mOfficeClosed ? 'rotate(0deg)': 'rotate(180deg)'}}/>
        </Lbl>
        <br/>
        <br/>
        <StyledSlideDown closed={this.state.mOfficeClosed} className={'private-office-m'}>

        <P>Großzügig geschnittener Raum mit modernem Arbeitskomfort und hochwertigem Design-Interieur hinter historischen Mauern.
          Das moderne Bad bietet die perfekte Abkühlung nach der Joggingrunde in der Mittagspause.</P>
        <P><b>Komfortabel und praktisch – ca. 25 m² für 1-5 Personen</b></P>
        <br/>
        <br/>

<P>2700,- pro Monat | 700,- pro Woche</P>
        <br/>
          <Lbl onClick={this.toggleMOfficeDetails}>
            <Header>Details</Header>

            <Arrow style={{transform: this.state.mOfficeDetailsClosed ? 'rotate(0deg)': 'rotate(180deg)'}}/>
          </Lbl>
          <br/>
          <br/>
          <StyledSlideDown closed={this.state.mOfficeDetailsClosed} className={'private-office-m-details'}>

          <ul>
          <li>5 Arbeitsplätze möglich</li>
          <li>frisches Obst</li>
          <li>Separater Eingang</li>
          <li>Desinfektionsservice</li>
          <li>Loungebereich</li>
          <li>WLAN</li>
          <li>Große Fenster</li>
          <li>Duschbad mit eigenem WC</li>
          <li>Handtücher / Badetücher</li>
          <li>Kaffee & Tee Bar</li>

        </ul>

        <P>Optional zubuchbare Services:</P>
        <ul>
          <li>Konferenzraum  (25 €/h)</li>
          <li>Minibar mit frischen Getränken (2,00 - 2,80 €)</li>
          <li>Frühstücksservice (15 €)</li>
          <li>Fahrradvermietung (12 €/Tag)</li>
        </ul>

        <P>Es besteht die Möglichkeit, bei einer größeren Personenzahl das Private Office S und das Private Office M
          zu kombinieren und somit gemeinsam auf einem Flur entspannt zu arbeiten.</P>
        </StyledSlideDown>
        </StyledSlideDown>

        <br/>
<h2>Jetzt anfragen</h2>
        <br/>
        <Flex>
        <OfferForm/>
        <Img src={img}></Img>
        </Flex>
      </Container>
    </Page>
  </IndexLayout>
    )
  }
}

export default Buchung
